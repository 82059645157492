@import url('@nimbus-ds/tokens/dist/css/variables.css');

:root {
  --background: var(--color-sys-light-neutral-surface);
}

/* The admin currently doesn't support dark mode. When it does, uncomment the following lines to provide dark mode support. */
/* @media (prefers-color-scheme: dark) {
  :root {
    --background: var(--color-sys-dark-neutral-surface);
  }
} */

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: initial;
}

html,
body {
  max-width: 100vw;
}

body {
  background-color: var(--background);
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #333;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
